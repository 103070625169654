.container {
	position: absolute;
	top: 85px;
	left: 150px;
	width: 300px;
	padding: 0;
	border-radius: 4px;
	transition: transform .2s ease-out;
}

.container.subpanelOpen {
	transform: translateX(200px);
}

.tabs {
	height: 33px;
	width: 100%;
	display: flex;
	margin-bottom: 0 !important;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-bottom: 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.boxMinimized .tabs {
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.tabButton {
	position: relative;
	height: 100% !important;
	width: 100%;
	padding: 0 0 2px 0;
	border: 1px solid white;
	border-radius: 0;
	box-shadow: none !important;
}

.boxMinimized .tabButton {
	padding: 0;
}

.tabButton:active, .tabButton:focus {
	border-color: white;
}

.tabButton.legend {
	border-top-left-radius: 2px;
}

.boxMinimized .tabButton.legend {
	border-right: 1px solid var(--bulma-button-border);
	border-bottom-left-radius: 2px;
}

.tabButton.statistics {
	border-top-right-radius: 2px;
}

.boxMinimized .tabButton.statistics {
	border-left: 1px solid var(--bulma-button-border);
	border-bottom-right-radius: 2px;
}

.container:not(.boxMinimized) .tabButton.selected {
	background-color: var(--main-dark);
	color: white;
	border-color: var(--main-dark);
}

.container:not(.boxMinimized) .tabButton:not(.selected) {
	border-bottom-color: var(--bulma-button-border);
}

.tabButton.legend:hover, .tabButton.statistics:hover,
.container:not(.boxMinimized) .tabButton:not(.selected):hover {
	border-color: var(--bulma-button-border-hover);
}

.container:not(.boxMinimized) .tabButton.selected:hover {
	border-color: var(--bulma-button-border);
}

.tabButton:not(.selected):active {
	background-color: var(--grey-hover-light);
}

.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	fill: var(--main-dark);
	margin-top: 1px;
}

.legend .icon {
	height: 100%;
}

.statistics .icon {
	height: 80%;
}

.container:not(.boxMinimized) .selected .icon {
	fill: white;
}

.displayContainer {
	position: absolute;
	top: 100%;
	left: 0;
	height: 215px;
	width: 100%;
	background-color: white;
	background-clip: padding-box;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-top: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	visibility: visible;
	opacity: 1;
	transform-origin: center top;
	transform: scaleY(1);
	transition: all .2s ease-out;
	overflow-y: auto;
}

.boxMinimized .displayContainer {
	visibility: hidden;
	opacity: 0;
	transform: scaleY(.1);
}

.display {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.displayHeader {
	text-align: center;
}

.displayHeader .title {
	font-size: 1.5rem;
	margin-bottom: 5px;
}

.displayHeader .date {
	font-size: .7rem;
	color: var(--bulma-button-border-hover);
}

.displayHeader .legendTitle {
	font-size: 1rem;
	line-height: 1.2rem;
	margin-top: 10px;
	padding: 0 3px;
}

.chartContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 7px;
}

.totalsContainer {
	height: 67%;
	width: 90%;
}

.legendContainer {
	width: 90%;
	height: 60%;
	display: flex;
	justify-content: center;
	margin-top: 6px;
}

/* MOBILE */
@media (max-width: 700px), (max-height: 499px) {
	.container {
		width: 200px;
	}

	.tabs {
		height: 30px;
	}

	.displayHeader .title {
		font-size: 1.2rem;
	}

	.displayHeader .legendTitle {
		font-size: .7rem;
		line-height: .8rem;
	}

	.displayHeader .date {
		font-size: .6rem;
	}

	.displayContainer {
		height: 180px;
	}
}

@media (max-width: 650px), (max-height: 570px) {
	.container {
		top: 54px;
		left: 100px;
	}

	.container.subpanelOpen {
		transform: translateX(150px);
	}

}

@media (max-width: 530px) and (min-height: 551px) {
	.container {
		position: absolute;
		top: auto;
		bottom: 2px;
		left: 50%;
		transform: translateX(-50%);
	}

	.container.subpanelOpen {
		transform: translateX(-50%);
	}

	.tabs {
		border-bottom: 2px solid rgba(0, 0, 0, 0.1);
		border-top: 0;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.boxMinimized .tabs {
		border-top: 2px solid rgba(0, 0, 0, 0.1);
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	.tabButton {
		height: 25px;
	}

	.tabButton.legend {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 2px;
	}

	.tabButton.statistics {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 2px;
	}

	.tabButton.legend:hover, .tabButton.statistics:hover {
		border-color: white;
	}

	.container:not(.boxMinimized) .tabButton:not(.selected) {
		border-bottom-color: white;
		border-top-color: var(--bulma-button-border);
	}

	.container:not(.boxMinimized) .tabButton.selected:hover {
		border-color: var(--main-dark);
	}

	.displayContainer {
		top: auto;
		bottom: calc(100%);
		border-top: 2px solid rgba(0, 0, 0, 0.1);;
		border-bottom: 0;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		transform-origin: center bottom;
	}
}

@media (max-width: 530px) and (max-height: 550px) {
	.container {
		top: 2px;
	}

	.container.subpanelOpen {
		transform: translateX(0);
	}
}

@media (max-height: 340px) {
	.container {
		top: 2px;
	}
}
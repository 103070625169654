.container {
	position: absolute;
	top: 90px;
	right: 15px;
}

.mapStyleButton {
	position: absolute;
	top: 0;
	right: 0;
	height: 30px;
	width: 30px;
	padding: 3px;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.layersIcon {
	height: 100%;
	width: 100%;
}

.menu {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	top: 35px;
	right: 0;
	background-color: white;
	border-radius: 4px;
	padding: 2px;
	transition: all .2s ease-out;
}

.menu.open {
	visibility: visible;
	opacity: 1;
	transform: translateY(10px);
}

.triangleIcon {
	position: absolute;
	top: -13px;
	right: 7px;
	height: 15px;
	z-index: 100;
	transform: rotate(-90deg);
	fill: white;
}

.menuButton {
	width: 100%;
	font-size: .9rem;
	border: 0;
}

.menuButton:hover {
	background-color: var(--grey-hover-light);
}

.menuButton.selected {
	background-color: var(--main-dark);
	color: white;
}

/* MOBILE */
@media (max-width: 650px) {
	.container {
		right: 4px;
	}
}

@media (max-height: 570px) {
	.container {
		top: 79px;
		right: 4px;
	}
}
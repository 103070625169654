.total {
	margin-top: 5px;
	font-size: .9rem;
	line-height: 1.2;
	text-align: center;
}


.label {
	color: var(--main-dark);
}

.value {
	margin-left: 5px;
}
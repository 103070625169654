.panel {
	position: relative;
	top: 15px;
	left: 15px;
	width: 100px;
	background-color: white;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 4px;
	z-index: 5;
}

/******* LOGO WITH BUTTON *******/
.logoContainer {
	position: relative;
	height: 70px;
	background-color: var(--color-logo);
	border-radius: 4px;
	margin-bottom: 2px;
}

.logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 85%;
}

.subpanelButton {
	position: absolute;
	bottom: 0;
	right: -23px;
	height: 24px;
	width: 24px;
	border: 2px solid rgba(0, 0, 0, 0.1);
	background-clip: padding-box;
	outline: none;
	border-radius: 4px;
	padding: 0;
	background-color: var(--main-dark);
	cursor: pointer;
	z-index: 10;
	transition: right .2s ease-out;
}

.subpanelButton:hover {
	background-color: var(--main-dark-hover);
}

.subpanelOpen .subpanelButton {
	right: -227px;
}

.triangleIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 60%;
	width: 60%;
	margin-left: 1px;
	fill: #e6e5e5;
	transition: transform .2s ease-out;
}

.subpanelOpen .triangleIcon {
	transform: translate(-50%, -50%) scaleX(-1);
	margin-left: -1px;
}


/******* PANEL BLOCKS *******/
.panelBlocksContainer {
	position: relative;
	height: 450px;
}

.panelBlock {
	position: relative;
	height: 20%;
	width: 100%;
	cursor: pointer;
	outline: none;
	background-color: white;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-bottom: 1px solid var(--bulma-button-border);
	border-radius: 4px;
	padding: 0;
}

.panelBlock:active, .panelBlock:focus {
	background-color: white;
}

@media (hover: hover) {
	.panelBlock:hover {
		background-color: var(--grey-hover-light);
	}
}

.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 70%;
	width: 70%;
}

.governance .icon {
	fill: var(--color-governance);
}

.governance.selected {
	background-color: var(--color-governance);
}

.governance.selected .icon {
	fill: white;
}

.social .icon {
	fill: var(--color-social);
}

.social.selected {
	background-color: var(--color-social);
}

.social.selected .icon {
	fill: white;
}

.environmental .icon {
	fill: var(--color-environmental);
}

.environmental.selected {
	background-color: var(--color-environmental);
}

.environmental.selected .icon {
	fill: white;
}

.economic .icon {
	fill: var(--color-economic);
}

.economic.selected {
	background-color: var(--color-economic);
}

.economic.selected .icon {
	fill: white;
}

.spatial .icon {
	fill: var(--color-spatial);
}

.spatial.selected {
	background-color: var(--color-spatial);
}

.spatial.selected .icon {
	fill: white;
}


/******* SUBPANEL BLOCKS *******/
.subpanel {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 0;
	left: calc(100% + 5px);
	height: 100%;
	width: 220px;
	padding: 2px 5px;
	background-color: white;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 4px;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	transform-origin: left center;
	transform: scaleX(.1);
	transition: all .2s ease-out;
	overflow-y: auto;
}

.subpanelOpen .subpanel {
	visibility: visible;
	opacity: 1;
	transform: scaleX(1);
}

.subpanelHeader {
	margin: 5px 0;
	text-transform: uppercase;
	letter-spacing: 4px;
	font-weight: bold;
}

.spatial .subpanelHeader {
	color: rgba(var(--color-spatial-rgb), 0.7);
}

.governance .subpanelHeader {
	color: rgba(var(--color-governance-rgb), 0.7);	
}

.economic .subpanelHeader {
	color: rgba(var(--color-economic-rgb), 0.8);
}

.social .subpanelHeader {
	color: rgba(var(--color-social-rgb), 0.7);
}

.environmental .subpanelHeader {
	color: rgba(var(--color-environmental-rgb), 0.7);
}

.subpanelBlock {
	position: relative;
	width: 100%;
	margin: 4px 0;
	cursor: pointer;
	outline: none;
	border: none !important;
	border-radius: 7px;
	font-size: .9rem;
	padding: 5px 8px;
	flex-shrink: 0;
}

.subpanelBlock.isGroup {
	padding-right: 23px;
}

.subpanelBlock.inGroup {
	width: 95%;
	align-self: flex-end;
}

.spatial .subpanelBlock {
	background-color: rgba(var(--color-spatial-rgb), 0.3);
}

.spatial .subpanelBlock:hover {
	background-color: rgba(var(--color-spatial-rgb), 0.4);
}

.governance .subpanelBlock {
	background-color: rgba(var(--color-governance-rgb), 0.3);
}

.governance .subpanelBlock:hover {
	background-color: rgba(var(--color-governance-rgb), 0.4);
}

.economic .subpanelBlock {
	background-color: rgba(var(--color-economic-rgb), 0.4);
}

.economic .subpanelBlock:hover {
	background-color: rgba(var(--color-economic-rgb), 0.5);
}

.social .subpanelBlock {
	background-color: rgba(var(--color-social-rgb), 0.3);
}

.social .subpanelBlock:hover {
	background-color: rgba(var(--color-social-rgb), 0.4);
}

.environmental .subpanelBlock {
	background-color: rgba(var(--color-environmental-rgb), 0.3);
}

.environmental .subpanelBlock:hover {
	background-color: rgba(var(--color-environmental-rgb), 0.4);
}

.subpanelBlock.selected {
	color: white;
}

.spatial .subpanelBlock.selected {
	background-color: var(--color-spatial);
}

.governance .subpanelBlock.selected {
	background-color: var(--color-governance);
}

.economic .subpanelBlock.selected {
	background-color: var(--color-economic);
}

.social .subpanelBlock.selected {
	background-color: var(--color-social);
}

.environmental .subpanelBlock.selected {
	background-color: var(--color-environmental);
}

.subpanelBlock .groupTriangle {
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translateY(-50%) rotate(90deg);
	margin-top: 1px;
	height: 15px;
	width: 15px;
	fill: #ffffff;
	transition: transform .1s ease-out;	
}

.subpanelBlock .groupTriangle.selected {
	transform: translateY(-50%) rotate(-90deg);
}

.subpanelBlock span {
	width: 100%;
	overflow-x: hidden;
	text-align: left;
}

/* Style SUBPANEL SCROLLBAR (Webkit only and only for desktop) */
@media (min-width: 1024px) {
	.subpanel::-webkit-scrollbar-track	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
		background-color: transparent;
		border-radius: 10px;
	}
	.subpanel::-webkit-scrollbar	{
		width: 11px;
		border-radius: 10px;
		background-color: transparent;
	}
	.subpanel::-webkit-scrollbar-thumb	{
		border-radius: 10px;
		border: 2px solid transparent;
		background-clip: padding-box;
		/*background-color: var(--main-dark);*/
	}
	.subpanel.spatial::-webkit-scrollbar-thumb {
		background-color: rgba(var(--color-spatial-rgb), 0.3);
	}
	.subpanel.governance::-webkit-scrollbar-thumb {
		background-color: rgba(var(--color-governance-rgb), 0.3);
	}
	.subpanel.economic::-webkit-scrollbar-thumb {
		background-color: rgba(var(--color-economic-rgb), 0.4);
	}
	.subpanel.social::-webkit-scrollbar-thumb {
		background-color: rgba(var(--color-social-rgb), 0.3);
	}
	.subpanel.environmental::-webkit-scrollbar-thumb {
		background-color: rgba(var(--color-environmental-rgb), 0.3);
	}
}

/* MOBILE */
@media (max-width: 650px), (max-height: 570px) {
	.panel {
		top: 4px;
		left: 4px;
		width: 60px;
	}

	.logoContainer {
		height: 50px;
	}

	.panelBlocksContainer {
		height: 280px;
	}

	.subpanelOpen .subpanelButton {
		right: -155px;
	}

	.subpanel {
		height: 100%;
		width: 150px;
	}

	.subpanelHeader {
		font-size: .8rem;
	}

	.subpanelBlock {
		margin: 3px 0;
		font-size: .7rem;
		padding: 4px 6px;
	}

	.subpanelBlock.isGroup {
		padding-right: 20px;
	}

	.subpanelBlock .groupTriangle {
		height: 12px;
		width: 12px;
	}
}

@media (max-width: 530px) and (max-height: 570px) {
	.subpanelButton {
		display: none;
	}
}

@media (max-height: 340px) {
	.panel {
		height: calc(100% - 8px);	
	}

	.logoContainer {
		height: 15%;
	}

	.panelBlocksContainer {
		height: 85%;
	}

	.subpanelButton {
		display: none;
	}
}
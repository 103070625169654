.loginContainer {
	margin-top: 70px;
	width: 400px;
}

.loginHeader {
	font-size: 1.4rem;
}

.box {
	margin-bottom: 10px !important;
}

.errorMessage {
	font-size: .9rem;
	color: var(--bulma-danger-color);
	margin-bottom: 10px;
}


/* MOBILE */
@media (max-width: 420px) {
	.loginContainer {
		width: 95%;
	}
}

@media (max-height: 400px) {
	.loginContainer {
		margin-top: 20px;
	}
	.loginHeader {
		margin-bottom: 15px !important;
	}
}
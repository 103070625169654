.admin {
	height: 100%;
	overflow-y: auto;
}

h1.title {
	margin: 20px auto 40px auto;
	font-size: 2.3rem;
	font-weight: bold;
	text-align: center;
	color: var(--main-dark);
}

.mainContainer {
	width: 800px;
	max-width: 95%;
	margin: 0 auto;
}

/********** PANEL **********/
.panelHeader {
	text-transform: uppercase;
	font-weight: bold;
	color: var(--main-dark);
	letter-spacing: 5px;
}

button.panelBtn {
	height: 50px;
	width: 100%;
	border-top: 0;
	border-bottom: 1px solid var(--bulma-button-border);
  border-left: 1px solid var(--bulma-button-border);
  border-right: 1px solid var(--bulma-button-border);
  line-height: 1.5;
}

button.panelBtn:hover, button.panelBtn:active, button.panelBtn:focus {
	background-color: var(--hover-whitesmoke);
	border-color: var(--bulma-button-border);
	box-shadow: none !important;
}

button.panelBtn.selected {
	background-color: var(--main-dark);
	color: white;
}

button.panelBtn.selected:hover, button.panelBtn.selected:active, button.panelBtn.selected:focus {
	background-color: var(--main-dark-hover);
}

.iconContainer {
	height: 2rem;
	width: 2rem;
	margin-right: 10px;
}

.icon {
	fill: var(--main-dark);
}

button.panelBtn.selected .icon {
	fill: white;
}

/********** LAYERS LIST **********/
.selectLayerText {
	margin: 10px 0 15px 0;
}

.layer {
	height: 50px;
	margin-bottom: 15px;
	border-radius: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 1rem;
	font-size: 1.1rem;
	cursor: pointer;
}

.layer:hover {
	background-color: var(--bulma-primary-color);
	color: white;
}

.updateIcon {
	fill: var(--bulma-primary-color);
	margin-right: 10px;
}

.layer:hover .updateIcon {
	fill: white;
}

/********** LOGOUT BUTTON **********/
.logoutBtn {
	display: block;
	width: 250px;
	font-size: 1.1rem;
	margin: 40px auto 10px;
}

/********** MODAL **********/
.modal {
	position: relative;
	border-radius: 6px;
}

section.modalContent {
	padding-bottom: 0;
}

.modalCloseBtn {
	position: absolute;
	top: 6px;
	right: 6px;
	background-color: rgba(10, 10, 10, 0.3);
}

.modalCloseBtn:hover {
	background-color: rgba(10, 10, 10, 0.4);
}

.modalHeader {
	font-size: 1.3rem;
	margin-bottom: 25px;
}

.layerToUpdate {
	margin-left: 10px;
	font-weight: bold;
}

.fileUpload {
	margin-top: 20px;
}

.uploadIcon {
	height: 90%;
	fill: var(--bulma-primary-color);
	margin-right: 10px;
}

.uploadMessageContainer {
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.messageWarning {
	color: var(--bulma-primary-color);
}

.messageSuccess {
	color: var(--bulma-success-color);
	display: flex;
	align-items: center;
}

.successIcon {
	fill: var(--bulma-success-color);
	margin-right: 7px;
}

/* MOBILE */
@media (max-width: 768px) {
	h1.title {
		margin-bottom: 30px;
	}

	.mainContainer {
		width: 85%;
	}

	.selectLayerText {
		margin-top: 0;
	}

	.logoutBtn {
		width: 85%;
		margin-top: 20px;
	}
}

@media (max-width: 599px) {
	h1.title {
		margin-top: 15px;
		margin-bottom: 15px;
		font-size: 1.5rem;
	}

	.panelHeader {
		font-size: 1rem;
	}
	
	button.panelBtn {
		font-size: 1rem;
		height: 40px;
	}

	.iconContainer {
		height: 1.6rem;
		width: 1.6rem;
	}

	.selectLayerText {
		margin-bottom: 10px;
	}

	.layer {
		font-size: 1rem;
		height: 40px;
		margin-bottom: 5px;
	}

	.logoutBtn {
		font-size: 1rem;
		margin-top: 15px;
	}

}
.row {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-top: 6px;
}

.square {
	height: 15px;
	width: 15px;
	min-height: 15px;
	min-width: 15px;
	margin-right: 5px;
	border-radius: 3px;
}

.label {
	font-size: .9rem;
	line-height: 1;
	margin-top: 1px;
}

.legendNoBins {
	height: 100%;
	width: 100%;
}

.squareNoBins {
	height: 20px;
	width: 20px;
	border-radius: 3px;	
	margin: 0 auto;
}

@media (max-width: 700px) {
	.row {
		margin-top: 4px;
	}
	.square {
		height: 12px;
		width: 12px;
	}
	.label {
		font-size: .7rem;
		margin-top: 2px;
	}
}
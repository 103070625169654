/* GLOBAL CSS VARIABLES */
:root {
  --color-logo: #095c9e;
  --color-spatial: #f07318;
  --color-governance: #8e83bc;
  --color-economic: #fddb64;
  --color-social: #1fa2e5;
  --color-environmental: #90ca3d;
  --color-spatial-rgb: 240, 115, 24; /* #f07318 */
  --color-governance-rgb: 142, 131, 188; /* #8e83bc */
  --color-economic-rgb: 253, 219, 100; /* #fddb64 */
  --color-social-rgb: 31, 162, 229; /* #1fa2e5 */
  --color-environmental-rgb: 144, 202, 61; /* #90ca3d */
  --main-dark: #797b7b;
  --main-dark-hover: #868888;
  --hover-whitesmoke: #f5f5f5;
  --grey-hover-light: #dddddd;
  --grey-hover-medium: #cdcdcd;
  --bulma-button-border: #dbdbdb;
  --bulma-button-border-hover: #b5b5b5;
  --bulma-primary-color: #00d1b2;
  --bulma-success-color: #23d160;
  --bulma-danger-color: #ff3860;

}

html, body, #root {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
  overflow-y: hidden !important;
  box-sizing: border-box;
}


/* MAPBOX CSS ADJUSTMENTS */
.mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  margin-top: 15px;
  margin-right: 15px;
}

.mapboxgl-popup {
  width: 250px;
  z-index: 50;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.mapboxgl-popup-content h1 {
	font-size: 1.1rem;
	margin-bottom: 10px;
}

.mapboxgl-popup-content p {
	font-size: .85rem;
}

.mapboxgl-popup-close-button.delete {
	position: absolute;
	top: 2px;
	right: 2px;
}

/* Fix */
.mapboxgl-ctrl-attrib-button {
  display: none;
}

/* D3 CSS */
.d3-x-axis text {
  font-size: .55rem;
  text-anchor: middle;
}

.d3-y-axis text {
  font-size: .5rem;
  text-anchor: middle;
}

.d3-x-axis-label, .d3-y-axis-label {
  font-size: .7rem;
  text-anchor: middle;
  opacity: 0;
}

/* MOBILE */
@media (max-width: 700px) {
  /* D3 CSS */
  .d3-x-axis text {
    font-size: .4rem;
  }

  .d3-y-axis text {
    font-size: .4rem;
  }

  .d3-x-axis-label, .d3-y-axis-label {
    font-size: .6rem;
  }
}

@media (max-width: 650px), (max-height: 570px) {
  /* MAPBOX CSS ADJUSTMENTS */
  .mapboxgl-ctrl-top-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
    margin-top: 4px;
    margin-right: 4px;
  }

  .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl{
    margin-left: 7px;
    margin-bottom: 1px;
  }

  .mapboxgl-ctrl-logo {
    width: 58px !important;
  }
}
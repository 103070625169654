.modalContent {
	position: relative;
	min-height: 240px;
	width: 450px;
	border-radius: 6px;
	padding: 20px 30px 0;
	background-color: white;
}

.modalCloseBtn {
	position: absolute;
	top: 6px;
	right: 6px;
	background-color: rgba(10, 10, 10, 0.3);
}

.modalText {
	font-size: .95rem;
	margin-bottom: 15px;
}

.errorMessage {
	margin-top: 10px;
	font-size: .9rem;
	line-height: 1rem;
	color: var(--bulma-danger-color);
	margin-bottom: 10px;
}

.messageSuccess {
	margin-top: 10px;
	color: var(--bulma-success-color);
	display: flex;
	align-items: center;
}

.successIcon {
	fill: var(--bulma-success-color);
	margin-right: 7px;
}

/* MOBILE */
@media (max-width: 475px) {
	.modalContent {
		width: 95%;
		/*height: 280px;*/
	}
}